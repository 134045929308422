import { useEffect, useState } from "react";

import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { reset } from "store/slice/voucher";

import {
  Establishment,
  UsableTimes,
  Input,
  InputMask,
  Loading,
  Message,
  TextArea,
  VoucherSample,
} from "components";

import { schema } from "./validate";

import VoucherAPICaller from "services/api/voucher";

import EstablishmentAPICaller from "services/api/establishment";
import { Establishment as IEstablishment } from "interfaces/Establishment";
import EstablishmentUnitAPICaller from "services/api/establishmentUnit";
import { formatPhone } from "utils/string/phone";
import { toValueLabel } from "utils/object";
import {
  EstablishmentUnit,
  EstablishmentUnitContactType,
} from "interfaces/EstablishmentUnit";
import { formatSite } from "utils/string/site";
import { useSelector } from "react-redux";
import { RootState } from "store/index";
import { Convenience } from "interfaces/Convenience";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FileUpload } from "components/FileUpload";
import { fileToBlobURL } from "services/io/file";
import Select, { SingleValue } from "react-select";
import { Option } from "components/InputSelect";
import { Substitution } from "interfaces/Substitution";

const aspectVoucher = { width: 1400, height: 650 };
const aspectLogo = { width: 500, height: 500 };
const projectName = process.env.REACT_APP_PROJECT_NAME;
const projectYear = process.env.REACT_APP_YEAR;
const categoryId = process.env.REACT_APP_CATEGORY_ID;
const takeawayId = process.env.REACT_APP_TAKEAWAY_ID;
const localConsumeId = process.env.REACT_APP_LOCAL_CONSUME_ID;
const cityName = process.env.REACT_APP_CITY;

const TAKEAWAY_OPTIONS = [
  {
    value: localConsumeId!,
    label: "Não, exclusivo para consumo no local",
  },
  {
    value: `${localConsumeId},${takeawayId}`,
    label:
      "Sim, temos consumo no local mas aceitaremos os pedidos para takeaway",
  },
  {
    value: takeawayId!,
    label: "Sim, somos exclusivamente takeaway",
  },
];

const HOLIDAY_OPTIONS = [
  { value: "true", label: "Sim, será aceito" },
  {
    value: "false",
    label: "Não, não será aceito",
  },
];

const COMMEMORATIVE_OPTIONS = [
  { value: "true", label: "Sim, será aceito" },
  {
    value: "false",
    label: "Não, não será aceito",
  },
];

export function Voucher() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState<null | {}>(null);

  const [imgVoucher, setImgVoucher] = useState("");
  const [imgLogo, setImgLogo] = useState("");
  const [imgVoucherFile, setImgVoucherFile] = useState<File>();
  const [imgLogoFile, setImgLogoFile] = useState<File>();

  const { data: savedData } = useSelector((state: RootState) => state.voucher);
  const { data: dataOption } = useSelector((state: RootState) => state.options);

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      establishment: {
        companyName: "",
      },
      voucher: {
        usableTimes: [
          {
            start: "",
            end: "",
            days: [],
          },
        ],
        substitutions: [],
      },

      address: [
        {
          zipCode: "",
          contacts: [
            {
              name: "",
              email: "",
              phone: "",
            },
          ],
          openTimes: [
            {
              start: "",
              end: "",
              days: [],
            },
          ],
        },
      ],
    },
  });

  const { setError } = methods;

  useEffect(() => {
    if (
      Object.keys(methods.formState.errors).length === 1 &&
      methods.formState.errors.voucher?.usableTimes
    ) {
      const section = document.querySelector("#horario_cupom");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [methods.formState.errors]);

  useEffect(() => {
    if (savedData?.establishment?.id) {
      let legalResponsable;
      let holiday = "";
      let commemorativeDate = "";
      let takeaway: Convenience[] | undefined = [];
      if (savedData?.units?.length) {
        legalResponsable = savedData.units[0]?.contacts?.find(
          // @ts-ignore
          (contact) => contact.contactType! === "legalResponsible"
        );

        if (savedData?.voucher?.id) {
          takeaway = savedData.units[0]?.conveniences?.filter(
            // @ts-ignore
            (item) => item.id === takeawayId || item.id === localConsumeId
          );

          // @ts-ignore
          const savedHoliday = savedData.voucher?.usableTimes.find(({ days }) =>
            days.includes("holiday")
          );
          holiday = savedHoliday?.id ? "true" : "false";

          const savedCommemorativeDate = savedData.voucher?.usableTimes.find(
            ({ days }) => days.includes("commemorativeDate")
          );

          commemorativeDate = savedCommemorativeDate ? "true" : "false";
        }
      }

      // @ts-ignore
      let substitutions = [];
      savedData.voucher?.substitutions?.forEach(({ id }: Substitution) => {
        // @ts-ignore
        substitutions.push(id);
      });

      const address = savedData?.units?.map((unit) => {
        const contacts = unit?.contacts
          ?.filter(
            // @ts-ignore
            (contact) => contact.contactType !== "legalResponsible"
          )
          .map((contact) => ({
            ...contact,
            contactType: {
              value: contact.contactType,
              label:
                EstablishmentUnitContactType[
                  contact.contactType as keyof typeof EstablishmentUnitContactType
                ],
            },
          }));

        // @ts-ignore
        let conveniences = [];
        unit?.conveniences
          ?.filter(
            (item) => item.id !== takeawayId && item.id !== localConsumeId
          )
          .forEach((item) => {
            // @ts-ignore
            conveniences[item.id] = item;
          });

        return {
          ...unit,
          state: toValueLabel(unit.state),
          contacts,
          // @ts-ignore
          conveniences,
        };
      });

      // @ts-ignore
      const selectedTakeaway =
        takeaway?.length === 2
          ? `${localConsumeId},${takeawayId}`
          : takeaway?.length
          ? takeaway[0].id
          : "";

      if (savedData.voucher?.images?.length) {
        // @ts-ignore
        setImgVoucher(savedData.voucher?.images[0].url);
      }

      if (savedData.establishment?.logoUrl) {
        setImgLogo(savedData.establishment?.logoUrl);
      }
      // @ts-ignore
      methods.reset({
        // @ts-ignore
        voucher: { ...savedData?.voucher!, substitutions },
        takeaway: TAKEAWAY_OPTIONS.find(
          (item) => item.value === selectedTakeaway
        ),
        holiday: HOLIDAY_OPTIONS.find((item) => item.value === holiday),
        commemorativeDate: COMMEMORATIVE_OPTIONS.find(
          (item) => item.value === commemorativeDate
        ),
        establishment: savedData.establishment,

        // @ts-ignore
        address,
        contact: legalResponsable,
      });
    } else {
      methods.setValue("establishment.cnpj", savedData?.cnpj!);
    }
  }, [savedData]);

  const saveEstablishment = async (data: any, image: any) => {
    let logo = [];
    if (!!imgLogoFile) {
      logo.push(imgLogoFile);
    } else {
      logo.push(imgLogo);
    }

    const establishmentParams = {
      ...data.establishment,
      site: formatSite(data.establishment.site),
      status: { value: "Temporariamente inativo" },
      type: { value: "Restaurante" },
      contactPhone: formatPhone(data.establishment.contactPhone),
      categories: [{ id: categoryId }],
      logo,
      image,
    };

    try {
      const response = await EstablishmentAPICaller.createOrUpdate(
        // @ts-ignore
        establishmentParams,
        setError
      );

      if (response.data.hasOwnProperty("errors")) {
        setMessage({
          message: "Ocorreu um erro ao salvar o estabelecimento.",
          title: "Erro!",
          className: "alert-danger",
        });
        return false;
      }

      // @ts-ignore
      if ((response.data as IEstablishment).id) {
        // @ts-ignore
        return (response.data as IEstablishment).id!;
      } else {
        return (response.data as { establishment: IEstablishment })
          .establishment.id!;
      }
    } catch {
      delete establishmentParams.image;
      delete establishmentParams.logo;

      const response = await EstablishmentAPICaller.createOrUpdate(
        // @ts-ignore
        establishmentParams,
        setError
      );

      // @ts-ignore
      if ((response.data as IEstablishment).id) {
        // @ts-ignore
        return (response.data as IEstablishment).id!;
      } else {
        return (response.data as { establishment: IEstablishment })
          .establishment.id!;
      }
    }
  };

  const saveEstablishmentUnits = async (establishmentId: string, data: any) => {
    const takeawayList = data.takeaway?.value
      ?.split(",")
      ?.map((key: string) => ({ id: key }));

    // @ts-ignore
    const units = data.address.map((address, index) => {
      const openTimes = Object.entries(address.openTimes).map(([_, value]) => {
        // @ts-ignore
        const { days, start, end, id, createdAt, updatedAt } = value;
        const parsedDays = days.reduce(
          (a: Object, v: string) => ({ ...a, [v]: true }),
          {}
        );
        return { ...parsedDays, start, end, id, createdAt, updatedAt };
      });

      const preparedConveniences = Object.entries(address.conveniences)
        .filter(([_, value]) => !!value)
        .map(([key, _]) => ({ id: key }));
      const conveniences = preparedConveniences.concat(takeawayList);

      const contacts = address.contacts
        // @ts-ignore
        ?.filter((it) => !!it?.name)
        // @ts-ignore
        ?.map((it) => {
          const contactType = it?.contactType?.value ?? "operational";
          return {
            ...it,
            phone: formatPhone(it.phone || ""),
            contactType:
              toValueLabel(
                EstablishmentUnitContactType[
                  contactType as keyof typeof EstablishmentUnitContactType
                ]
              ) ?? contactType,
          };
        });

      if (index === 0) {
        const { id: contactId, ...contactData } = data.contact;
        contacts.push({
          ...contactData,
          ...(!!contactId && { id: contactId }),
          contactType: toValueLabel(
            EstablishmentUnitContactType["legalResponsible"]
          ),
        });
      }

      return {
        establishmentId,
        ...address,
        name:
          address?.name ??
          `${address.district} - ${address.street} - ${data.establishment.name}`,
        status: { value: "Ativo" },
        state: { value: address.state.value },
        city: { value: address.city },
        district: { value: address.district },

        openTimes,
        contacts,
        conveniences,
      };
    });

    const unitList = await Promise.all(
      // @ts-ignore
      units.map(async (unitData) => {
        const response = await EstablishmentUnitAPICaller.createOrUpdate<
          typeof data
        >(unitData, () => {
          throw Error("Erro ao salvar as unidades");
        });

        if (response.data.hasOwnProperty("errors")) return;

        const {
          data: { unit },
        } = response as { data: { unit: EstablishmentUnit } };

        return { id: unit.id };
      })
    );

    return unitList;
  };

  const saveVoucher = async (data: any) => {
    try {
      // @ts-ignore
      await VoucherAPICaller.createOrUpdate(data, setError);
    } catch {
      delete data.images;
      // @ts-ignore
      await VoucherAPICaller.createOrUpdate(data, setError);
    }
  };

  const onSubmitHandler = async (data: any) => {
    let newUsableTimes = {};
    if (data.holiday?.value === "true") {
      newUsableTimes = Object.assign({ holiday: true }, newUsableTimes);
    }
    if (data.commemorativeDate?.value === "true") {
      newUsableTimes = Object.assign(
        { commemorativeDate: true },
        newUsableTimes
      );
    }

    const usableTimes = Object.entries(data?.voucher?.usableTimes).map(
      ([_, value]) => {
        // @ts-ignore
        const { days, start, end, id, createdAt, updatedAt } = value;

        const ignoreDays = ["holiday", "commemorativeDate"];
        const parsedDays = days
          .filter((day: string) => !ignoreDays.includes(day))
          .reduce((a: Object, v: string) => ({ ...a, [v]: true }), {});
        return {
          ...newUsableTimes,
          ...parsedDays,
          start,
          end,
          id,
          createdAt,
          updatedAt,
        };
      }
    );

    const substitutions = data.voucher.substitutions.map((key: string) => ({
      id: key,
    }));

    try {
      setLoading(true);

      let images = [];
      if (!!imgVoucherFile) {
        images.push(imgVoucherFile);
      } else {
        images.push(imgVoucher);
      }

      const establishmentId = await saveEstablishment(data, images);
      if (!establishmentId) throw Error("Erro ao salvar o estabelecimento");

      const establishmentUnitIds = await saveEstablishmentUnits(
        establishmentId,
        data
      );

      if (Object.keys(establishmentUnitIds ?? {}).length === 0)
        throw Error("Erro ao salvar as unidades");

      const param = {
        id: data.voucher.id,
        name: data.establishment.name,
        establishmentId: { value: establishmentId },
        establishmentUnits: establishmentUnitIds,
        categoryId: { value: categoryId },
        planId: { value: dataOption.planId },
        voucherStatus: { value: "Ativo" },
        voucherType: { value: "Normal" },
        establishmentRules: data.voucher.establishmentRules,
        onPurchaseOf: data.voucher.onPurchaseOf,
        reward: { value: data.voucher.reward.label },
        usableTimes,
        substitutions,
        images,
      };

      await saveVoucher(param);

      setSuccess(true);
      setMessage({
        message: (
          <div>
            <strong>Obrigado!</strong>
            <br />
            Resposta enviada com sucesso! Em breve, nossa equipe entrar&aacute;
            em contato pelo WhatsApp {process.env.REACT_APP_CONTACT_PHONE} para
            aprovar a sua participa&ccedil;&atilde;o!
          </div>
        ),
        title: "Sucesso!",
        className: "alert-success",
      });
    } catch (err) {
      console.log("catch", err);
      // @ts-ignore
      if (methods?.formState?.errors?.name?.message === "Já existe") {
        setMessage({
          message:
            "Já existe um estabelecimento em nosso banco de dados com esse nome, por favor, adicione algum complemento no campo “Nome do Estabelecimento”",
          title: "Erro!",
          className: "alert-danger",
        });
      } else {
        setMessage({
          message: "Ocorreu um erro na gravação dos dados",
          title: "Erro!",
          className: "alert-danger",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  console.log("ERRO", methods.formState.errors);

  return (
    <FormProvider {...methods}>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        style={{ zIndex: 9999999 }}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <form onSubmit={methods.handleSubmit(onSubmitHandler)} role="account">
        <input type="hidden" {...methods.register("establishment.id")} />
        <input type="hidden" {...methods.register("voucher.id")} />
        <input type="hidden" {...methods.register("contact.id")} />

        <div className="scroll">
          <div id="cadastro" className="col-11 col-xl-6 offset-xl-1">
            <div className="col-12">
              <h2>Cadastro Estabelecimento Tour {projectYear}</h2>
              <p>
                <strong>
                  <i>
                    Seu estabelecimento foi selecionado para participar da nova
                    edi&ccedil;&atilde;o do {projectName}!
                  </i>
                </strong>
                <br />
                Preencha as informa&ccedil;&otilde;es abaixo com o m&aacute;ximo
                de aten&ccedil;&atilde;o para que possamos dar sequ&ecirc;ncia
                no contrato e confec&ccedil;&atilde;o do voucher para
                aprova&ccedil;&atilde;o.
              </p>

              <p>
                <strong>
                  <i className="blue-color">
                    Precisamos de algumas informa&ccedil;&otilde;es para o seu
                    contrato! :)
                  </i>
                </strong>
                <br />
                Nos campos a seguir, precisamos de algumas
                informa&ccedil;&otilde;es sobre a sua empresa e o
                respons&aacute;vel legal, que assinar&aacute; o seu contrato de
                parceria com o {projectName}!
              </p>
            </div>

            <div className="d-flex flex-wrap justify-content-center justify-content-lg-between row">
              <div className="col-md-6">
                <h3>
                  CNPJ <span className="asterisco">*</span>
                </h3>
                <Controller
                  render={({ field }) => (
                    <InputMask
                      className="input-oqfc"
                      mask="cnpj"
                      placeholder="Preencha somente n&uacute;meros"
                      error={
                        methods.formState.errors?.establishment?.cnpj?.message
                      }
                      {...field}
                      disabled={true}
                    />
                  )}
                  // @ts-ignore
                  name={"establishment.cnpj"}
                  control={methods.control}
                />
              </div>
              <div className="col-md-6">
                <h3>
                  Raz&atilde;o Social <span className="asterisco">*</span>
                </h3>

                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="Nome como consta no CNPJ"
                  form={methods.register("establishment.companyName")}
                  error={
                    methods.formState.errors.establishment?.companyName?.message
                  }
                />
              </div>
              <div className="col-12">
                <h3>
                  NOME DO ESTABELECIMENTO <span className="asterisco">*</span>
                </h3>
                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="Qual o nome do seu estabelecimento?"
                  form={methods.register("establishment.name")}
                  error={methods.formState.errors.establishment?.name?.message}
                />
              </div>

              <div className="col-12">
                <h3>
                  DADOS DE QUEM ASSINARÁ O CONTRATO{" "}
                  <span className="asterisco">*</span>
                </h3>
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="Nome Completo"
                  form={methods.register("contact.name")}
                  error={methods.formState.errors.contact?.name?.message}
                />
              </div>
              <div className="col-md-6">
                <InputMask
                  className="input-oqfc"
                  mask="cpf"
                  placeholder="CPF, apenas n&uacute;meros"
                  form={methods.register("contact.cpf")}
                  error={methods.formState.errors.contact?.cpf?.message}
                />
              </div>

              <div className="col-md-6">
                <Input
                  type="text"
                  className="input-oqfc"
                  placeholder="E-mail"
                  form={methods.register("contact.email")}
                  error={methods.formState.errors.contact?.email?.message}
                />
              </div>

              <div className="col-md-6">
                <InputMask
                  className="input-oqfc"
                  mask="phone"
                  placeholder="Telefone, apenas n&uacute;meros"
                  form={methods.register("contact.phone")}
                  error={methods.formState.errors.contact?.phone?.message}
                />
              </div>
            </div>
          </div>
          <div
            id="beneficio"
            className="col-12 d-flex flex-wrap row justify-content-center justify-content-xl-start"
          >
            <div className="col-lg-11 col-xl-6 offset-xl-1 d-flex flex-wrap justify-content-between">
              <h4>Benef&iacute;cio ofertado para o tour</h4>
              <p>
                Descreva abaixo o benef&iacute;cio proposto para quem tem o
                Tour. <br />
                Note que as inclus&otilde;es no formul&aacute;rio ainda
                passar&atilde;o por uma avalia&ccedil;&atilde;o da equipe antes
                de serem aprovadas.
              </p>

              <h5>Dicas do Tour:</h5>
              <p>
                &bull; Se voc&ecirc; escolher para o benef&iacute;cio a
                op&ccedil;&atilde;o &quot;ganhe outro igual ou de menor
                valor&quot;, o Tour abranger&aacute; o seu card&aacute;pio
                completo. Ou seja, o custo com o segundo prato/item (bonificado)
                pode ser menor e o usu&aacute;rio ter&aacute; mais
                op&ccedil;&otilde;es para conhecer o seu card&aacute;pio e
                retornar outras vezes! &#128521;
              </p>

              <p>
                &bull; Obrigatoriamente o benefício escolhido precisa constar no
                cardápio regular do estabelecimento e estar disponível para o
                público geral.
              </p>

              <p>
                &bull; Cada vez mais, &eacute; importante pensar nas pessoas que
                tem restri&ccedil;&otilde;es alimentares. Se optar por um
                benef&iacute;cio com ingredientes de origem animal, que tal
                pensar em tamb&eacute;m oferecer uma op&ccedil;&atilde;o
                substituta para alguns casos, quando eventualmente o cliente
                tenha esse tipo de restri&ccedil;&atilde;o? &#10024;
              </p>

              <div className="row col-12">
                <div className="col-md-8">
                  <h3>
                    Na compra de:<span className="asterisco">*</span>
                  </h3>
                  <Input
                    type="text"
                    className="input-oqfc"
                    placeholder="Ex: qualquer prato; um combo de bebida e hamb&uacute;guer; etc."
                    form={methods.register("voucher.onPurchaseOf")}
                    error={
                      methods.formState.errors.voucher?.onPurchaseOf?.message
                    }
                  />
                </div>
                <div className="col-md-4">
                  <h3>
                    Ganhe<span className="asterisco">*</span>
                  </h3>

                  <Controller
                    name="voucher.reward"
                    render={({
                      field: { ref, onChange, ...props },
                      formState: { errors },
                    }) => (
                      <div className="oqfc-input-container">
                        <Select
                          ref={ref}
                          {...props}
                          placeholder="Selecione uma opção"
                          className="input-oqfc-select"
                          //@ts-ignore
                          options={dataOption?.benefits}
                          onChange={(v: SingleValue<Option>) => onChange(v)}
                        />
                        {errors.voucher?.reward?.value?.message && (
                          <span className="text-danger">
                            {errors.voucher?.reward?.value?.message}
                          </span>
                        )}
                      </div>
                    )}
                    control={methods.control}
                  />
                </div>
                <div className="col-12">
                  <h3>REGRAS DE UTILIZA&ccedil;&atilde;O:</h3>
                  <p>Todos os vouchers do Tour seguem nossas regras gerais:</p>
                  <p>
                    &bull; Utilização em pelo menos duas pessoas (exceto em
                    modalidade takeaway)
                  </p>
                  <p>
                    &bull; O desconto não é cumulativo com outras promoções ou
                    itens adicionais (couvert, rolha, bebidas, happy hour, etc)
                  </p>
                  <p>
                    &bull; Caso a opção escolhida para o benefício seja
                    diferente do dobro (itens iguais), o estabelecimento deverá
                    considerar, no ato do desconto, o item compreendido na conta
                    que tenha valor imediatamente inferior ao item do cardápio
                    descrito no voucher
                  </p>

                  <TextArea
                    rows={6}
                    type="textarea"
                    className="input-oqfc"
                    placeholder="Deseja adicionar alguma informação?&#10;Essas informações também serão avaliadas pela equipe do Tour antes de serem aprovadas."
                    form={methods.register("voucher.establishmentRules")}
                    error={
                      methods.formState.errors.voucher?.establishmentRules
                        ?.message
                    }
                  />
                </div>

                <div
                  id="horario_cupom"
                  className="col-12 horarios-beneficio d-flex flex-wrap p-0 align-content-start row"
                >
                  <h3 className="col-12">
                    DIAS E HOR&Aacute;RIOS DE ACEITA&Ccedil;&Atilde;O DO
                    BENEF&iacute;CIO
                  </h3>
                  <p>
                    * Note que nesta edi&ccedil;&atilde;o, pedimos para que
                    considere pelo menos 4 dias de aceita&ccedil;&atilde;o.
                  </p>
                  <UsableTimes />
                </div>
              </div>
              <div className="col-12 d-flex flex-wrap p-0 align-items-center justify-content-center row">
                <div className="col-md-7 aceita-beneficio">
                  <h3>O BENEF&Iacute;CIO SER&aacute; ACEITO EM FERIADOS?</h3>
                  <p>Ex.: Dia do Trabalho, Corpus Christi, etc.</p>
                </div>
                <div className="col-md-5 aceita-beneficio">
                  <Controller
                    control={methods.control}
                    name="holiday"
                    render={({
                      field: { ref, onChange, ...props },
                      formState: { errors },
                    }) => (
                      <div className="oqfc-input-container">
                        <Select
                          ref={ref}
                          {...props}
                          placeholder="Selecione uma opção"
                          className="input-oqfc-select"
                          options={HOLIDAY_OPTIONS}
                          onChange={(v: SingleValue<Option>) => onChange(v)}
                        />
                        {errors?.holiday?.value?.message && (
                          <span className="text-danger">
                            {errors?.holiday?.value?.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="col-md-7 aceita-beneficio">
                  <h3>
                    O BENEF&iacute;CIO SER&aacute; ACEITO EM DATAS
                    COMEMORATIVAS?
                  </h3>
                  <p>
                    Ex.: dia dos pais, dia das m&atilde;es, dia dos namorados,
                    etc.
                  </p>
                </div>
                <div className="col-md-5 aceita-beneficio">
                  <Controller
                    control={methods.control}
                    name="commemorativeDate"
                    render={({
                      field: { ref, onChange, ...props },
                      formState: { errors },
                    }) => (
                      <div className="oqfc-input-container">
                        <Select
                          ref={ref}
                          {...props}
                          placeholder="Selecione uma opção"
                          className="input-oqfc-select"
                          options={COMMEMORATIVE_OPTIONS}
                          onChange={(v: SingleValue<Option>) => onChange(v)}
                        />
                        {errors?.commemorativeDate?.value?.message && (
                          <span className="text-danger">
                            {errors?.commemorativeDate?.value?.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </div>

                {cityName !== "londrina" && (
                  <>
                    <div className="col-md-7 aceita-beneficio">
                      <h3>
                        O BENEF&iacute;CIO &Eacute; V&aacute;LIDO PARA A
                        MODALIDADE TAKEAWAY?
                      </h3>
                      <p>
                        Ao aceitar essa modalidade, os usu&aacute;rios
                        n&atilde;o precisam consumir no local e podem pedir o
                        benef&iacute;cio para a retirada no balc&atilde;o.
                      </p>
                    </div>
                    <div className="col-md-5 aceita-beneficio">
                      <Controller
                        control={methods.control}
                        name="takeaway"
                        render={({
                          field: { ref, onChange, ...props },
                          formState: { errors },
                        }) => (
                          <div className="oqfc-input-container">
                            <Select
                              ref={ref}
                              {...props}
                              placeholder="Selecione uma opção"
                              className="input-oqfc-select"
                              options={TAKEAWAY_OPTIONS}
                              //@ts-ignore
                              onChange={(v: SingleValue<Option>) => onChange(v)}
                            />
                            {errors?.takeaway?.value?.message && (
                              <span className="text-danger">
                                {errors?.takeaway?.value?.message}
                              </span>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </>
                )}
                <div className="col-12 d-flex flex-wrap p-0 align-items-center justify-content-center row">
                  <h3>
                    CASO O PÚBLICO TENHA ALGUMA RESTRIÇÃO ALIMENTAR, É POSSÍVEL{" "}
                    <span className="asterisco">SUBSTITUIR</span> POR ALGUMA
                    DESSAS OPÇÕES?
                  </h3>

                  <div className="row">
                    {dataOption?.substitutions?.map((item) => (
                      <div
                        className="col-md-5 col-xl-6 col-xxl-5 beneficios align-content-start d-flex flex-wrap"
                        key={item.value}
                      >
                        <input
                          id={`voucher.substitutions[${item.value}]`}
                          type="checkbox"
                          value={item.value}
                          {...methods.register(`voucher.substitutions`)}
                        />

                        <label htmlFor={`voucher.substitutions[${item.value}]`}>
                          &nbsp;&nbsp;{item.label}
                        </label>
                        <br />
                      </div>
                    ))}
                  </div>
                  {methods.formState.errors?.voucher?.substitutions
                    ?.message && (
                    <span className="text-danger">
                      {methods.formState.errors.voucher.substitutions.message}
                    </span>
                  )}
                </div>
                <div className="col-12 aceita-beneficio mt-5">
                  <h3>FA&Ccedil;A O UPLOAD DA FOTO QUE ILUSTRA O BENEFÍCIO</h3>
                  <p>
                    A imagem deve ter no máximo 5mb e deve ser em *.png ou
                    *.jpg, seguindo a dimensão recomendada de 1400x650px.
                  </p>
                </div>
                <div className="add-img add-foto">
                  <div className="upload__content">
                    <FileUpload
                      image={
                        !!imgVoucher
                          ? "assets/img/btn-foto-adicionada.png"
                          : "assets/img/btn-add-foto.png"
                      }
                      accept="image/*"
                      maxSizeInBytes={5_000_000}
                      minWidth={500}
                      minHeight={500}
                      cropProps={{
                        aspect: aspectVoucher.width / aspectVoucher.height,
                      }}
                      hideLabel
                      multiple={false}
                      openCropImageModal
                      maxWidthOrHeight={1400}
                      onChange={(file) => {
                        setImgVoucher(fileToBlobURL(file[0]));
                        setImgVoucherFile(file[0]);
                      }}
                      label="Escolher uma ou mais imagens"
                    />
                  </div>
                </div>
                {/* <!--					<div className="col-12 cardapio-upload d-flex flex-wrap">-->
					<!--						<h3>FA&ccedil;A O UPLOAD DO CARD&aacute;PIO DO ESTABELECIMENTO</h3>-->
					<!--						<input id="link" type="text" className="input-oqfc" placeholder="Link do card&aacute;pio">-->
					<!--					</div>-->
					<!--					<div className="add-img add-file">-->
					<!--						<img src="img/btn-add-file.png" className="img-fluid">-->
					<!--					</div>--> */}
              </div>
            </div>
          </div>
          <div
            id="dados-gerais"
            className="col-12 d-flex flex-wrap row justify-content-center justify-content-xl-start"
          >
            <div className="col-lg-11 col-xl-7 col-xxl-6 offset-xl-1 d-flex flex-wrap justify-content-lg-between row">
              <h4>Dados gerais do seu estabelecimento</h4>
              <p>
                Esses dados s&atilde;o referentes ao seu estabelecimento para o
                p&uacute;blico geral. Por isso, quando falamos de hor&aacute;rio
                de funcionamento, atributos e etc, preencha pensando nas
                informa&ccedil;&otilde;es gerais do seu estabelecimento!
              </p>

              <div className="col-lg-12">
                <h3>Descri&ccedil;&atilde;o do Local</h3>
                <TextArea
                  className="input-oqfc"
                  rows={3}
                  form={methods.register("establishment.description")}
                  error={
                    methods.formState.errors.establishment?.description?.message
                  }
                />
              </div>
              <div className="col-lg-6">
                <h3>Site</h3>
                <Input
                  id="site"
                  type="text"
                  className="input-oqfc"
                  placeholder="URL do seu site"
                  form={methods.register("establishment.site")}
                  error={methods.formState.errors.establishment?.site?.message}
                />
              </div>
              <div className="col-lg-6">
                <h3>Whatsapp de Contato</h3>

                <InputMask
                  className="input-oqfc"
                  mask="phone"
                  placeholder="(XX) XXXXX-XXXX"
                  form={methods.register("establishment.contactPhone")}
                  error={
                    methods.formState.errors?.establishment?.contactPhone
                      ?.message
                  }
                />
              </div>

              <div className="col-12 p-0 d-flex flex-wrap justify-content-center">
                <h3 className="col-12 p-0">
                  FA&ccedil;A O UPLOAD DO LOGO DO ESTABELECIMENTO
                </h3>
                <p>
                  O logo deve ser em *.png ou *.jpg, quadrado, com o fundo
                  branco ou transparente, seguindo a dimens&atilde;o recomendada
                  de 500x500 px.
                </p>

                <div className="add-img add-logo">
                  <div className="upload__content">
                    <FileUpload
                      image={
                        !!imgLogo
                          ? "assets/img/btn-logo-adicionado.png"
                          : "assets/img/btn-add-logo.png"
                      }
                      accept="image/*"
                      maxSizeInBytes={5_000_000}
                      minWidth={100}
                      minHeight={100}
                      cropProps={{
                        aspect: aspectLogo.width / aspectLogo.height,
                      }}
                      hideLabel
                      multiple={false}
                      openCropImageModal
                      maxWidthOrHeight={500}
                      onChange={(file) => {
                        setImgLogo(fileToBlobURL(file[0]));
                        setImgLogoFile(file[0]);
                      }}
                      label="Escolher uma ou mais imagens"
                    />
                  </div>
                </div>
              </div>
              <div id="div-endereco">
                <Establishment
                  conveniences={dataOption?.conveniences}
                  shoppings={dataOption?.shoppings}
                />
              </div>

              <div>
                <br />
                <br />
                <p>
                  <strong>
                    Confirme antes de enviar as informa&ccedil;&otilde;es do
                    benef&iacute;cio escolhido aplicadas no voucher de exemplo,
                    dispon&iacute;vel ao lado (no desktop) ou abaixo (no mobile)
                  </strong>
                </p>
              </div>

              {(savedData?.establishment?.status === "Inativo" ||
                !savedData?.establishment?.status) && (
                <div className="col-12 d-flex flex-wrap justify-content-center">
                  <button type="submit" className="btnEnviar">
                    Enviar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <VoucherSample
          image={imgVoucher}
          logo={imgLogo}
          convenienceList={dataOption?.allConveniences}
        />

        {loading && <Loading />}
        {message && (
          // @ts-ignore
          <Message
            {...message}
            handleClose={async () => {
              if (success) {
                await dispatch(reset());
                navigate("/");
              } else {
                setMessage(null);
              }
            }}
          />
        )}
      </form>
    </FormProvider>
  );
}
